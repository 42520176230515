<!-- This was from a tutorial by LearnVue, found at https://www.youtube.com/watch?v=ROGeJxI2L58 -->

<template>
    <div class="tabs">
        <ul class="tabs__header">
            <li 
                v-for="title in tabTitles" 
                :key="title" 
                :class="{ selected: title == selectedTitle}" 
                @click="selectedTitle = title"    
            >
                {{ title }}
            </li>    
        </ul>

        <slot />
    </div>
</template>

<script>
import { ref, provide } from 'vue';

export default {
    setup(props, { slots }) {
        const tabTitles = ref(slots.default().map((tab) => tab.props.title));
        const selectedTitle = ref(tabTitles.value[0]);

        provide("selectedTitle", selectedTitle);

        return {
            tabTitles,
            selectedTitle
        }
    },
}
</script>

<style scoped>
    .tabs {
        min-height: 100%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tabs__header {
        margin-bottom: 15px;
        list-style-type: none;
        padding: 0;
        display: flex;
    }

    .tabs__header li {
        width: 275px;
        
        text-align: center;
        font-weight: bold;

        padding: 15px 20px;
        margin: 0 5px;

        background-color: var(--tab-inactive-color);
        border-radius: 5px;

        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    .tabs__header li:hover {
        background-color: var(--tab-hover-color);
    }

    .tabs__header li.selected {
        background-color: var(--tab-active-color);
        color: var(--tab-text-color);
    }

    @media only screen and (max-width: 1490px) and (min-width: 745px) {
        .tabs__header li {
            width: 175px;
            padding: 12px 20px;
        }
    }

    @media only screen and (max-width: 990px) and (min-width: 745px) {
        .tabs__header li {
            width: 125px;
        }
    }

    @media only screen and (max-width: 745px) {
        .tabs__header {
            flex-direction: column;
            width: 100%;
            min-width: 125px;
        }

        .tabs__header li {
            margin: 5px 0;
            width: 100%;
        }
    }

    /* @media only screen and (max-width: 1201px) and (min-width: 605px) {
        .tabs__header li {
            width: 175px;
            padding: 12px 20px;
        }
    }

    @media only screen and (max-width: 810px) and (min-width: 605px) {
        .tabs__header li {
            width: 125px;
        }
    }

    @media only screen and (max-width: 604px) {
        .tabs__header {
            flex-direction: column;
            width: 100%;
            min-width: 125px;
        }

        .tabs__header li {
            margin: 5px 0;
            width: 100%;
        }
    } */
</style>