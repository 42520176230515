<!-- This was from a tutorial by LearnVue, found at https://www.youtube.com/watch?v=ROGeJxI2L58 -->

<template>
    <div class="tab-content" v-show="title == selectedTitle">
        <div class="transition-animation-div">
            <slot />
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';

export default {
    props: ['title'],
    setup() {
        const selectedTitle = inject('selectedTitle');

        return {
            selectedTitle
        }
    }
}
</script>

<style scoped>
    .tab-content {
        width: 100%;
        min-width: 120px;
        min-height: 100%;
        padding: 20px;
        border-radius: 5px;

        box-shadow: 0 0px 5px 5px var(--light-box-shadow-color);
    }

    .transition-animation-div {
        animation: fadeEffect 0.5s;
    }

    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }
</style>