<template>
    <div class="title">
        <div
            class="skeleton-title"
            :style="'--title-width:' + (300 + getRandomInt(-50, 50)).toString() + 'px;'"
        >
        </div>
        <h2>{{ subtitle }}</h2>
    </div>
</template>

<script>
export default {
    name: "SatelliteTitleSkeleton",
    props: {
        subtitle: {
            type: String,
            required: false
        }
    },
    setup() {
        function getRandomInt(min, max) {
            return Math.random() * (max - min) + min;
        }

        return {
            getRandomInt,
        }
    }
}
</script>

<style scoped>
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .skeleton-title {
        --title-width: 250px;
        width: var(--title-width);
        height: 37px;

        margin-top: 10px;
        margin-bottom: 5px;

        overflow: hidden;

        border-radius: 3px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .skeleton-title::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        
        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    h2 {
        margin-top: 0;
        color: darkgray;
    }
</style>