<template>
    <div v-if="true" class="satellite-information">
        <Suspense>
            <template #default>
                <satellite-title :subtitle="ncn" />
            </template>

            <template #fallback>
                <satellite-title-skeleton :subtitle="ncn"/>
            </template>
        </Suspense>

        <div id="overview">
            <h3 class="subtitle">Overview (from <a href="https://nssdc.gsfc.nasa.gov/" class="header-link" target="_blank" rel="noopener noreferrer">NSSDCA</a>)</h3>
            
            <Suspense>
                <template #default>
                    <satellite-description :ncn="ncn" />
                </template>
                <template #fallback>
                    <satellite-description-skeleton />
                </template>
            </Suspense>
        </div>
       
        <div id="data">
            <h3 class="subtitle">More Information</h3>

            <TabWrapper>
                 <Tab title="All Data">
                    <Suspense>
                        <template #default>
                            <SatelliteDataTable
                                title="All Data"
                                title-link="#"
                                source="all"
                                :ncn="ncn"
                                :addToHistory="true"
                            />
                        </template>
                        <template #fallback>
                            <satellite-data-table-skeleton title="All Data" :row-count="38" />
                        </template>
                    </Suspense>
                </Tab>

                <Tab title="CelesTrak">
                    <Suspense>
                        <template #default>
                            <SatelliteDataTable
                                title="CelesTrak"
                                title-link="https://celestrak.com/"
                                source="celestrak"
                                :ncn="ncn"
                                :addToHistory="true"
                                :showDateSelect="true"
                            />
                        </template>
                            <template #fallback>
                                <satellite-data-table-skeleton title="CelesTrak" title-link="https://celestrak.com/" :showDateSelect="true" :row-count="19" />
                            </template>
                    </Suspense>
                </Tab>

                <Tab title="Planet4589">
                    <Suspense>
                        <template #default>
                            <SatelliteDataTable
                                title="Planet4589"
                                title-link="https://planet4589.org/"
                                source="planet4589"
                                :ncn="ncn"
                                :addToHistory="true"
                                :showDateSelect="true"
                                :hasExpandableContent="true"
                            />
                        </template>
                        <template #fallback>
                            <satellite-data-table-skeleton title="Planet4589" title-link="https://planet4589.org/" :showDateSelect="true" :row-count="32" />
                        </template>
                    </Suspense>
                </Tab>

                <Tab title="UCS">
                    <Suspense>
                        <template #default>
                            <SatelliteDataTable
                                title="Union of Concerned Scientists"
                                title-link="https://www.ucsusa.org/"
                                source="ucs"
                                :ncn="ncn"
                                :addToHistory="true"
                                :showDateSelect="true"
                            />
                        </template>
                        <template #fallback>
                            <satellite-data-table-skeleton title="Union of Concerned Scientists" title-link="https://www.ucsusa.org/" :showDateSelect="true" :row-count="31" />
                        </template>
                    </Suspense>
                </Tab>
            </TabWrapper>
        </div>
        
        <div id="suggested">
            <h3 class="subtitle">Suggested Satellites</h3>

            <div class="suggested-satellites">
                <div class="suggested-pair">
                    <quick-link
                        class="suggested-ql"
                        :displayText="adjacentSatellites[0]"
                        :routerLink="'/satellite/' + adjacentSatellites[0]"
                        :showLink="false"
                    />
                    <quick-link
                        class="suggested-ql"
                        :displayText="adjacentSatellites[1]"
                        :routerLink="'/satellite/' + adjacentSatellites[1]"
                        :showLink="false"
                    />
                </div>

                <div class="suggested-pair">
                    <quick-link
                        class="suggested-ql"
                        :displayText="adjacentSatellites[2]"
                        :routerLink="'/satellite/' + adjacentSatellites[2]"
                        :showLink="false"
                    />
                    <quick-link
                        class="suggested-ql"
                        :displayText="adjacentSatellites[3]"
                        :routerLink="'/satellite/' + adjacentSatellites[3]"
                        :showLink="false"
                    />
                </div>
            </div>
        </div>
    </div>

    <div id="sat-not-found-message" v-else-if="!ncnValid">
        <h1>Sorry, we couldn't find a satellite with that NORAD Catalog Number</h1>
        <h3>Please try searching for another NORAD Catalog Number</h3>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Tab from '../components/common/Tab.vue';
import TabWrapper from '../components/common/TabWrapper.vue';

import SatelliteDataTable from '../components/satellite/SatelliteDataTable.vue';
import SatelliteDescription from '../components/satellite/SatelliteDescription.vue';
import SatelliteTitle from '../components/satellite/SatelliteTitle.vue';
import QuickLink from '../components/common/QuickLink.vue';

import SatelliteDataTableSkeleton from '../skeleton components/SatelliteDataTableSkeleton.vue';
import SatelliteDescriptionSkeleton from '../skeleton components/SatelliteDescriptionSkeleton.vue';
import SatelliteTitleSkeleton from '../skeleton components/SatelliteTitleSkeleton.vue';
// import OrbitHistorySkeleton from '../skeleton components/OrbitHistorySkeleton.vue';

export default {
    name: 'Satellite',
    components: {
        Tab,
        TabWrapper,
    
        SatelliteDataTable,
        SatelliteDescription,
        SatelliteTitle,
        QuickLink,

        SatelliteDataTableSkeleton,
        SatelliteDescriptionSkeleton,
        SatelliteTitleSkeleton,
        // OrbitHistorySkeleton,
    },
    setup() {
        const route = useRoute();
        const ncn = computed(() => route.params.ncn); // NORAD Catalog Number

        const store = useStore();
        const ncnValid =  store.getters["satellite_data/ncnValid"];

        // Reset the completion status of all the sources as soon as the page loads 
        store.commit("satellite_data/setAllSourceCompletion", false);

        const satName = computed(function () {
            if (store.state.satellite_data.satData.celestrak[0]) {
                return store.state.satellite_data.satData.celestrak[0]["Name"];
            }

            return " ";
        });

        const adjacentSatellites = setAdjacentSatellites();

        const showGrafanaDashboard = ref(store.state.satellite_data.completedSources.ucs);
        const noGrafanaData = computed(() => {
            if (!showGrafanaDashboard.value)
                return false;

            if (store.state.satellite_data["satData"]["ucs"] == [{}])
                return true;
            else if (!store.state.satellite_data["selectedData"]["ucs"]["Class of Orbit"] || store.state.satellite_data["selectedData"]["ucs"]["Class of Orbit"].toLowerCase() !== "leo")
                return true;
            
            return false;
        });
        const grafanaLink = computed(() => {
            if (!showGrafanaDashboard.value)
                return "";
            
            return `https://tle-history.intrack.solutions/d/QgcuXgRVk/leo?orgId=1&kiosk&theme=${store.state.global.theme}&var-norad_id=${ncn.value}`;
        });

        function zeroFill(number, width) {
            width -= number.toString().length;
            
            if ( width > 0 ) {
                return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
            }
            
            return number + ""; // Always return a string
        }

        function getAdjacentSatellite(step) {
            return zeroFill(parseInt(ncn.value) + step, 5);
        }

        function setAdjacentSatellites() {
            let ncnInt = parseInt(ncn.value);

            if (ncnInt === 1) {
                return [
                    getAdjacentSatellite(1),
                    getAdjacentSatellite(2),
                    getAdjacentSatellite(3),
                    getAdjacentSatellite(4),
                ];
            } else if (ncnInt === 2) {
                return [
                    getAdjacentSatellite(-1),
                    getAdjacentSatellite(1),
                    getAdjacentSatellite(2),
                    getAdjacentSatellite(3),
                ];
            }
            
            return [
                getAdjacentSatellite(-2),
                getAdjacentSatellite(-1), 
                getAdjacentSatellite(1), 
                getAdjacentSatellite(2),
            ];
            
            // TODO: deal with when the ncn is at the top (when there is no, or only one, satellite above)
        }

        watch(() => store.state.satellite_data.completedSources.ucs, () => showGrafanaDashboard.value = store.state.satellite_data.completedSources.ucs);

        return {
            ncn,
            satName,
            ncnValid,
            adjacentSatellites,
            showGrafanaDashboard,
            noGrafanaData,
            grafanaLink,
        }
    }
}
</script>

<style scoped>
    .satellite-information {
        min-height: 100%;
        height: 100%;
    }

    .error {
        color: var(--error-message-color);
    }

    .subtitle {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .header-link {
        text-decoration: none;
        color: var(--main-text-color);

        transition: 0.2s ease-out;
    }

    .header-link:hover {
        text-decoration: underline;
    }

    .description {
        text-align: justify;
        margin: 5px 0 0 0;
    }

    .grafana-iframe {
        width: 100%;
        height: 1067px;

        margin-top: 12px;

        border-radius: 5px;
    }

    .orbit-history-title {
        margin: auto;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: var(--main-text-color);

        background-color: inherit;
    }

    .no-orbit-history-indicator {
        color: var(--error-message-color);
        margin: 10px auto;
    }

    .suggested-satellites {
        width: 100%;
        margin-top: 10px;

        display: flex;
        justify-content: center;
    }

    .suggested-pair {
        width: 1000px;

        display: flex;
    }

    .suggested-ql {
        margin-right: 10px;
    }

    #title h1 {
        margin-bottom: 5px;
    }
    
    #title h2 {
        margin-top: 0;
        color: darkgray;
    }

    #date-dropdown-div {
        margin: 10px auto 0 auto;
    }

    #overview {
        margin-bottom: 20px;
    }

    #data {
        padding: 0 15px 0 15px;
        transition: 0.2s ease-out;
    }

    #suggested {
        margin-top: 20px;
        width: 100%;
    }

    #sat-not-found-message {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }

    #sat-not-found-message h1 {
        margin: 0;
    }

    @media only screen and (max-width: 1780px) {
        .suggested-satellites {
            flex-direction: column;
            align-items: center;
        }
    }

    @media only screen and (max-width: 1055px) {
        .suggested-pair {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        .suggested-ql {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
    
    @media only screen and (max-width: 583px) {
        #data {
            padding: 0;
        }
    }
</style>
