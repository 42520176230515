<template>
    <div class="satellite-description">
        <div class="description-skeleton-line long"></div>
        <div class="description-skeleton-line long"></div>
        <div class="description-skeleton-line long"></div>
        <div class="description-skeleton-line long"></div>
        <div class="description-skeleton-line long"></div>

        <div class="description-skeleton-line short"></div>
    </div>
</template>

<style scoped>
    .satellite-description {
        max-width: 2300px;
        width: 100%;

        margin: 5px auto 0 auto;
    }

    .description-skeleton-line {
        height: 15px;

        overflow: hidden;

        border-radius: 3px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .description-skeleton-line::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        
        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .long {
        width: 100%;
        margin-bottom: 10px;
    }

    .short {
        width: 250px;
    }
</style>
