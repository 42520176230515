<template>
    <div class="satellite-description">
        <div class="description-span" v-if="description !== null && description !== undefined && description.trim().length !== 0">
            <img class="satellite-image" v-if="imageURL != null && imageURL != undefined" :src="imageURL" alt="Image of the satellite">

            <p class="description">
                {{ description }}
            </p>
        </div>

        <p class="no-description-indicator" v-else>
            No description available
        </p>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "SatelliteDescription",
    props: {
        ncn: {
            type: String,
            required: true,
        }
    },
    async setup(props) {
        const store = useStore();
        let description_cache = store.state.satellite_data.selectedData.description_cache;

        if (description_cache.name && description_cache.ncn === props.ncn) {
            store.commit("satellite_data/setDataPoint", { soruce: "nssdca", dataObject: description_cache.description, dataPoint: "Description" })
            await store.dispatch("satellite_data/refreshDataPoint", { source: "nssdca", ncn: props.ncn, dataPoint: "imageURL" });
        } else {
            await store.dispatch("satellite_data/refreshData", { source: "nssdca", ncn: props.ncn, addToHistory: false });
        }

        const description = computed(() => store.state.satellite_data.selectedData.nssdca["Description"]);
        const imageURL = computed(() => store.state.satellite_data.selectedData.nssdca["Image URL"]);

        return {
            description,
            imageURL,
        }
    },
}
</script>

<style scoped>
    .satellite-description {
        max-width: 2300px;
        width: 100%;

        margin: 5px auto 0 auto;
    }

    .description-span {
        display: flex;
    }

    .description {
        text-align: justify;
        text-justify: auto;
        white-space: pre-line;
    }

    .satellite-image {
        margin: 20px 25px 0 0;

        max-width: 250px;
        max-height: 250px;
        width: auto;
        height: auto;
    }

    .no-description-indicator {
        margin-top: 0;

        color: var(--secondary-text-color);
        font-weight: bold;
    }

    @media only screen and (max-width: 945px) {
        .description-span {
            flex-direction: column;
            align-items: center;


        }

        .satellite-image {
            margin: 10px 0 0 0;

            max-width: 300px;
            max-height: 300px;
            width: auto;
            height: auto;
        }
    }

    @media only screen and (max-width: 345px) {
        .satellite-image {
            max-width: 250px;
            max-height: 250px;
            width: auto;
            height: auto;
        }
    }
</style>
