<template>
    <div id="title">
        <h1>{{ satName }}</h1>
        <h2>{{ subtitle }}</h2>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "SatelliteTitle",
    props: {
        subtitle: {
            type: String,
            required: false,
        },
    },
    async setup(props) {
        const store = useStore();
        let name_cache = store.state.satellite_data.selectedData.name_cache;
        
        if (!name_cache || !name_cache.name || name_cache.ncn !== props.subtitle) {
            let celestrakRefreshComplete = computed(() => store.state.satellite_data.completedSources.celestrak);
            await store.dispatch("global/until", () => celestrakRefreshComplete.value == true)
        }

        const satName = computed(function () {
            if (name_cache.name && name_cache.ncn === props.subtitle) {
                return name_cache.name;
            } else if (store.state.satellite_data.satData.celestrak[0]) {
                return store.state.satellite_data.satData.celestrak[0]["Name"];
            }

            return " ";
        });

        return {
            satName,
        }
    },
}
</script>

<style scoped>
    #title h1 {
        margin-top: 10px;
        margin-bottom: 5px;
    }
    
    #title h2 {
        margin-top: 0;
        color: darkgray;
    }

</style>